<template>
  <v-app>
    <v-app-bar
        id="appbar"
        app
        color="#dd0011"
        dark
        absolute
    >
      <div class="d-flex align-center">
        <v-img
            alt="logo"
            contain
            :src="header1"
            transition="scale-transition"
            width="70"
        />
      </div>
      <v-spacer/>
      <v-card flat height="100%" color="transparent" class="d-flex align-end">
        <v-img
            alt="logo"
            :src="header3"
            transition="scale-transition"
            width="120"
            height="50"
        />
      </v-card>
      <v-spacer/>
      <div class="d-flex align-center">
        <v-img
            alt="logo"
            contain
            :src="header1"
            transition="scale-transition"
            width="70"
        />
      </div>

    </v-app-bar>
    <v-main>
      <template v-if="!errorPageVisible">
        <template v-if="noSubmit">
          <v-container id="container" class="d-flex align-center">
            <v-card flat tile max-width="800" class="mx-auto">
              <v-form ref="form" lazy-validation>
                <template v-if="step === 1">
                  <div class="d-flex justify-center">
                    <h1 class="top-title">SEE YOU AT 2023 IBC!</h1>
                  </div>
                  <div class="d-flex justify-center">
                    <div class="d-flex flex-column justify-center mr-4">
                      <div>
                        <v-img width="60" :src="Logo"/>
                      </div>
                    </div>
                    <v-divider vertical class="ma-2"/>
                    <div>
                      <v-img width="100" :src="Logo2"/>
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="red_color font-weight-medium text-center">
                      SEI transforms your Android TV into a Plug-in and Play Smart Home Theater
                    </p>
                    <v-row>
                      <v-col cols="12">
                        <v-img
                            class="banner-pic"
                            v-for="(pic,index) in carousel.pics"
                            :key="index"
                            :src="pic"
                            max-width="100%"
                            width="100%"
                            aspect-ratio="1.91"/>
                      </v-col>
                    </v-row>

                    <h2 class="red_color font-weight-medium text-center mt-8 mb-5">Choose your interests</h2>

                    <v-row dense>
                      <v-col
                          cols="6"
                          v-for="item in listProducts"
                          :key="item.id"
                          @click="handleSelectProduct(item)">
                        <v-card flat
                                :class="['product-container d-flex flex-column justify-space-between', {'active': item.selected}]">
                          <div class="product-img d-flex justify-center">
                            <v-img :src="item.img" contain max-height="90" max-width="100%" width="130" height="90"/>
                          </div>
                          <div class="product-text body-2 px-2 d-flex align-center justify-center">
                            <span>{{ item.name }}</span>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                  <h2 class="red_color font-weight-medium text-center mt-8 mb-5">Make an appointment</h2>
                  <v-col cols="12">
<!--                    <v-autocomplete ref="timezone"-->
<!--                                    v-model="selectTimezone"-->
<!--                                    :items="timezonesList"-->
<!--                                    :rules="formRules.timezone"-->
<!--                                    label="Timezone"-->
<!--                                    outlined-->
<!--                                    item-text="text"-->
<!--                                    item-value="value"-->
<!--                                    return-object-->
<!--                                    @change="handleTimeZoneChange">-->
<!--                      <template v-slot:selection="data">-->
<!--                        <v-card flat tile color="transparent" width="100%">{{ data.item.text }}</v-card>-->
<!--                      </template>-->
<!--                    </v-autocomplete>-->
                   <v-card class="mb-4 text-left" tile flat>
                     +{{ selectTimezone.offset }} {{selectTimezone.text  }}
                   </v-card>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="selectDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="selectDate"
                            :rules="formRules.selectDate"
                            persistent-placeholder
                            label="Pick a date（required）"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        />
                      </template>
                      <v-date-picker
                          v-model="selectDate"
                          :show-current="`${partyYear}-${('0' + partyMonth).slice(-2)}`"
                          :min="datePickerMinDate"
                          :max="datePickerMaxDate"
                          color="#dd0011"
                          no-title
                          scrollable
                          @change="$refs.menu.save(selectDate)"
                      />
                    </v-menu>
                    <v-select v-if="selectDate"
                              v-model="selectedAppointment"
                              item-disabled="disabled"
                              persistent-placeholder
                              label="Pick a time（required）"
                              :rules="formRules.pickTime"
                              :items="timeList"
                              outlined/>
                  </v-col>
                  <v-col cols="12">
                    <div class="bottom-info">
                      Using our unique <u class="red_color">Exhibitor invite Code: IBC7858</u> which will grant you
                      access
                      to
                      IBC 2023 and our booth and please feel free to contact us!<br>
                    </div>
                  </v-col>
                </template>
                <template v-else-if="step === 2">
                  <v-col>
                    <div class="top-back d-flex align-center mb-5" @click="step = 1">
                      <v-icon class="mr-2" color="grey"> {{ icons.mdiArrowLeft }}</v-icon>
                      Back
                    </div>
                  </v-col>

                  <h2 id="step2-top" class="red_color font-weight-medium text-center">Continue with login</h2>
                  <v-col>
                    <div class="login-box">
                      <a href="#" class="social-button" id="google-connect" @click.prevent="handleGoogle">
                        <span>Connect with Google</span></a>
                      <a href="#" class="social-button" id="apple-connect" @click.prevent="handleApple"> <span>Connect with Apple</span></a>
                      <a href="#" class="social-button" id="linkedin-connect" @click.prevent="handleLinkedin"> <span>Connect with LinkedIn</span></a>
                    </div>
                  </v-col>
                  <h2 class="red_color font-weight-medium text-center mt-4 mb-4">Or register</h2>
                  <v-col cols="12">
                    <v-text-field v-model="formField.userName"
                                  label="Name（required）"
                                  persistent-placeholder
                                  :rules="formRules.userName"
                                  outlined/>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="formField.companyName"
                                  label="Company"
                                  persistent-placeholder
                                  :rules="formRules.companyName"
                                  outlined/>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="formField.email"
                                  label="Email（required）"
                                  persistent-placeholder
                                  :rules="formRules.email"
                                  outlined/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="formField.lookingFor"
                                label="What are you looking for?"
                                :rules="formRules.lookingFor"
                                outlined/>
                  </v-col>
                </template>
              </v-form>
            </v-card>
          </v-container>
          <div class="step-box container mb-5 d-flex justify-space-between px-6">
            <v-btn color="#dd0011" dark large block @click="handleStepNextClick" :loading="waitingRes">
              {{ step === 1 ? 'next' : 'submit' }}
            </v-btn>
          </div>
        </template>
        <template v-else>
          <v-card flat tile max-width="800" class="mx-auto success-container">
            <div class="d-flex justify-center">
              <h1 class="top-title">SEE YOU AT 2023 IBC!</h1>
            </div>
            <div class="d-flex justify-center">
              <div class="d-flex flex-column justify-center mr-4">
                <div>
                  <v-img width="60" :src="Logo"/>
                </div>
              </div>
              <v-divider vertical class="ma-2"/>
              <div>
                <v-img width="100" :src="Logo2"/>
              </div>
            </div>
            <v-col cols="12">
              <v-img class="banner-pic"
                     :src="submitBanner"
                     max-width="100%"
                     width="100%"
                     aspect-ratio="1.91"/>
            </v-col>
          </v-card>
        </template>
      </template>
      <template v-else>
        <v-card flat tile max-width="800" class="mx-auto">
          <div class="error-box d-flex flex-column align-center">
            <v-icon size="60" color="#dd0011">{{ icons.mdiAlertCircleOutline }}</v-icon>
            <h1 class="top-error-msg mt-1 mb-6">service error</h1>
            <v-btn color="#dd0011" dark @click="errorPageVisible = false">go back</v-btn>
          </div>
        </v-card>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import dayJSUtc from 'dayjs/plugin/utc'
import { mdiAlertCircleOutline, mdiArrowLeft } from '@mdi/js'

import Logo from '@/assets/logo.jpg'
import Logo2 from '@/assets/img_v2_4b4b127e-e9c2-4eda-9df4-7fe28618b4eg.png'
import header1 from '@/assets/header1.jpg'
import header2 from '@/assets/header2.jpeg'
import header3 from '@/assets/header3-compress.png'
import { timezonesList } from './config'

dayjs.extend(timezone)
dayjs.extend(dayJSUtc)
const targetTimezone = {
  offset: 2,
  utc: 'Europe/Amsterdam'
}
const partyYear = 2023
const partyMonth = 9
const partyStartDate = 15
const partyEndDate = 18
export default {
  name: 'App',
  computed: {
    timeList() {
      // 展会在阿姆斯特丹，时区为UTC+2
      if (!this.selectTimezone) {
        return []
      }
      return this.getCurTimeRangeList(targetTimezone, this.selectTimezone.offset, 30)
    }
  },
  filters: {
    selectDateFilters(val) {
      if (val) {
        return dayjs(val).format('YYYY-MM-DD')
      }
      return 'Please select a time first'
    }
  },
  data() {
    return {
      Logo,
      Logo2,
      header1,
      header2,
      header3,
      partyYear,
      partyMonth,
      partyStartDate,
      partyEndDate,
      icons: {
        mdiAlertCircleOutline,
        mdiArrowLeft
      },
      selectedAppointment: null,
      submitBanner: require('@/assets/banner/banner3-compress.png'),
      waitingRes: false,
      errorPageVisible: false,
      carousel: {
        model: 0,
        pics: [
          require('@/assets/banner/banner4-compress.png')
        ]
      },
      menu: false,
      loginChannel: null,
      step: 1,
      listProducts: [
        {
          name: 'Smart ATV Speaker & Soundbar',
          img: '/img/products/3bb56244-3199-4955-bb25-ba2a657fd862.png',
          selected: false
        },
        {
          name: 'AR Theater',
          img: '/img/products/2db26b18-dfa5-418b-a23a-d7ab725bdd1e.png',
          selected: false
        },
        {
          name: 'Set Top Box',
          img: '/img/products/10794701-5257-4dd8-b5e1-df69e77a6c3a.png',
          selected: false
        },
        {
          name: ' CPE & Wifi',
          img: '/img/products/product3.png',
          selected: false
        },
        {
          name: 'Home IoT',
          img: '/img/products/product4.png',
          selected: false
        },
        {
          name: 'Accessories',
          img: '/img/products/product5.png',
          selected: false
        }
      ],
      timezonesList,
      targetTimezone,
      formField: {
        userName: '',
        companyName: '',
        email: '',
        startTime: null,
        endTime: null,
        lookingFor: '',
        avatar: null
      },
      selectDate: null,
      selectTimezone: null,
      formRules: {
        userName: [
          v => !!v || 'This item is required',
          v => v.length <= 32 || 'This item should be less than 32 characters'
        ],
        companyName: [
          v => (!v || v.length <= 100) || 'This item should be less than 100 characters'
        ],
        email: [
          v => !!v || 'This item is required',
          v => /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/.test(v) || 'Please enter a legal email',
          v => v.length <= 100 || 'This item should be less than 100 characters'
        ],
        timezone: [
          v => !!v || 'This item is required'
        ],
        selectTime: [
          v => !!v || 'This item is required'
        ],
        pickTime: [
          v => !!v || 'This item is required'
        ],
        selectDate: [
          v => !!v || 'This item is required'
        ]
      },
      noSubmit: true,
      activity: null,
      datePickerMinDate: `${partyYear}-${('0' + partyMonth).slice(-2)}-${partyStartDate}`,
      datePickerMaxDate: `${partyYear}-${('0' + partyMonth).slice(-2)}-${partyEndDate}`
    }
  },
  created() {
    const queryString = location.href.split('?')[1]
    if (queryString) {
      const params = queryString.split('&')
      console.log('params', params)
      params.forEach(item => {
        const [key, value] = item.split('=')
        if (key === 'activity') {
          this.activity = value
        }
      })
      const cacheData = localStorage.getItem(this.activity)
      if (cacheData) {
        const { selectDate, selectedAppointment, listProducts, selectTimezone } = JSON.parse(cacheData)
        if (selectDate) {
          this.selectDate = selectDate
        }
        if (selectedAppointment) {
          this.selectedAppointment = selectedAppointment
        }
        if (selectTimezone) {
          this.selectTimezone = selectTimezone
        }
        if (listProducts) {
          this.listProducts = listProducts
        }
        if (this.selectDate && this.selectedAppointment) {
          this.step = 2
        }
        if (cacheData) {
          const { channel } = JSON.parse(cacheData)
          if (channel) {
            this.loginChannel = channel
            if (channel === 'linkedin') {
              this.handleGetLinkedInUserInfo()
            } else if (channel === 'google') {
              this.handleGetGoogleUserInfo()
            } else if (channel === 'apple') {
              this.handleGetAppleUserInfo()
            }
          }
        }
      } else {
        // const systemTimezone = (0 - new Date().getTimezoneOffset() / 60)
        // const systemTimezone = 2
        // console.log("systemTimezone", systemTimezone);
        this.selectTimezone = {
          offset: 2,
          text: 'Amsterdam, The Netherlands'
        }
      }
      // 日期选择范围判断
      // 获取当前时刻的阿姆斯特丹时间
      const nowTime = this.getCurAmsterdamTime()
      const nowHours = nowTime.hour()
      // 展会开始后
      if (this.isPartyPlaying()) {
        if (nowHours >= 18) {
          // 今天展会结束
          this.datePickerMinDate = nowTime.add(1, 'days').format('YYYY-MM-DD')
        } else {
          this.datePickerMinDate = nowTime.format('YYYY-MM-DD')
        }
      }
    }
  },
  methods: {
    dayjs,
    handleTimeZoneChange(e) {
      this.selectedAppointment = null
      if (e) {
        this.$refs.timezone.blur()
      }
    },
    handleSelectProduct(item) {
      item.selected = !item.selected
    },
    setCacheData(channel) {
      const json = JSON.stringify({
        channel,
        selectTimezone: this.selectTimezone,
        selectDate: this.selectDate,
        selectedAppointment: this.selectedAppointment,
        listProducts: this.listProducts
      })
      localStorage.setItem(this.activity, json)
    },
    getRandomStr(length = 16) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      let randomStr = ''
      for (let i = 0; i < length; i++) {
        randomStr += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return randomStr
    },
    handleGetLinkedInUserInfo() {
      const sessionKey = window.localStorage.getItem('linkedinSession')
      if (!sessionKey) return
      this.fetchWithRetry(`${process.env.VUE_APP_BASE_API}/auth/linkedin/getUserInfo?key=${sessionKey}`, {
        method: 'GET',
        // credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      }).then(res => {
        console.log('res', res)
        const { email, firstName, lastName } = res.data || {}
        this.formField.userName = `${firstName} ${lastName}`
        this.formField.email = email
        this.$nextTick(() => {
          if (!this.$refs.form.validate()) return
          this.handleSubmit()
        })
      })
    },
    handleGetAppleUserInfo() {
      const sessionKey = window.localStorage.getItem('appleSession')
      if (!sessionKey) return
      this.fetchWithRetry(`${process.env.VUE_APP_BASE_API}/auth/apple/getUserInfo?key=${sessionKey}`, {
        method: 'GET',
        // credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      }).then(res => {
        console.log('res', res)
        const { email, firstName, lastName } = res.data || {}

        this.formField.userName = ''
        this.formField.email = email
        this.$nextTick(() => {
          if (!this.$refs.form.validate()) return
          this.handleSubmit()
        })
      })
    },
    handleGetGoogleUserInfo() {
      const sessionKey = window.localStorage.getItem('googleSession')
      if (!sessionKey) return
      this.fetchWithRetry(`${process.env.VUE_APP_BASE_API}/auth/google/getUserInfo?key=${sessionKey}`, {
        method: 'GET',
        // credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      }).then(res => {
        console.log('res', res)
        const { email, firstName, lastName } = res.data || {}
        this.formField.userName = `${firstName} ${lastName}`
        this.formField.email = email
        this.$nextTick(() => {
          if (!this.$refs.form.validate()) return
          this.handleSubmit()
        })
      })
    },
    handleGoogle() {
      const value = this.getRandomStr(16)
      this.setCacheData('google')
      window.localStorage.setItem('googleSession', value)
      const openUrl = `${process.env.VUE_APP_BASE_API}/auth/google/oauth2?redirectUrl=${encodeURIComponent(window.location.href + `&key=${value}`)}`
      window.location.href = openUrl
    },
    handleApple() {
      const value = this.getRandomStr(16)
      this.setCacheData('apple')
      window.localStorage.setItem('appleSession', value)
      const openUrl = `${process.env.VUE_APP_BASE_API}/auth/apple/oauth2?redirectUrl=${encodeURIComponent(window.location.href + `&key=${value}`)}`
      window.location.href = openUrl
    },
    handleLinkedin() {
      const value = this.getRandomStr(16)
      this.setCacheData('linkedin')
      window.localStorage.setItem('linkedinSession', value)
      const openUrl = `${process.env.VUE_APP_BASE_API}/auth/linkedin/oauth2?redirectUrl=${encodeURIComponent(window.location.href + `&key=${value}`)}`
      window.location.href = openUrl
    },
    handleStepNextClick() {
      console.log('next', this.step)
      if (!this.$refs.form.validate()) return
      if (this.step === 1) {
        this.step = 2
        document.getElementById('appbar').scrollIntoView()
        return
      }
      // submit
      this.handleSubmit()
    },
    handlePrevious() {
      this.step = 1
    },
    fetchWithRetry(url, options, maxRetries = 3, retryDelay = 1000) {
      return new Promise((resolve, reject) => {
        function fetchAttempt(attempt) {
          fetch(url, options)
            .then(res => res.json())
            .then(res => {
              if (res.success) {
                resolve(res)
              } else {
                if (attempt < maxRetries) {
                  setTimeout(() => {
                    fetchAttempt(attempt + 1)
                  }, retryDelay)
                } else {
                  reject(new Error(`Maximum retries reached: ${maxRetries}`))
                }
              }
            })
            .catch(error => {
              if (attempt < maxRetries) {
                setTimeout(() => {
                  fetchAttempt(attempt + 1)
                }, retryDelay)
              } else {
                reject(error)
              }
            })
        }

        fetchAttempt(1)
      })
    },
    handleSubmit() {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LeHWYgnAAAAALRGzplz20qb8xmPI-D_b2hieW4F', { action: 'submit' }).then((token) => {
          console.log(this.timeList, this.selectedAppointment)
          const { startTime, endTime } = this.timeList.find(item => item.value === this.selectedAppointment)
          const systemTimezone = (0 - new Date().getTimezoneOffset() / 60)
          const timezoneOffset = systemTimezone - this.selectTimezone.offset
          // 计算出目标时区的时间戳
          const targetStartTime = dayjs(startTime).add(timezoneOffset, 'hours').valueOf()
          const targetEndTime = dayjs(endTime).add(timezoneOffset, 'hours').valueOf()
          console.log(this.formField)
          const { userName, email, lookingFor, companyName, avatar } = this.formField
          const products = this.listProducts.filter(item => !!item.selected).map(item => ({
            name: item.name,
            imgUrl: window.location.origin + item.img
          }))
          const submitData = {
            verifyToken: token,
            activity: this.activity,
            activityType: 'IBC',
            userName,
            email,
            locale: navigator?.language,
            timezone: this.selectTimezone.offset,
            companyName,
            meetingStartTime: targetStartTime,
            meetingEndTime: targetEndTime,
            timezoneArea: this.selectTimezone.text,
            formFields: [
              {
                key: 'What are you looking for?',
                value: lookingFor
              }
            ],
            products,
            loginChannel: this.loginChannel,
            userAvatar: avatar
          }
          this.waitingRes = true
          this.fetchWithRetry(`${process.env.VUE_APP_BASE_API}/public/questionnaireV2/submit`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(submitData)
          }, 1).then(res => {
            console.log(res)
            if (res.success) {
              this.noSubmit = false
              window.localStorage.clear()
            }
          }).catch(err => {
            console.error(err)
            this.errorPageVisible = true
          }).finally(() => {
            this.waitingRes = false
          })
        }).catch(err => {
          console.error(err)
        })
      })
    },

    timeAdd(time, period) {
      const result = dayjs(time).add(period, 'minute')
      return result.valueOf()
    },
    getCurAmsterdamTime() {
      const systemTimezone = (0 - new Date().getTimezoneOffset() / 60)
      const timezoneOffset = this.selectTimezone.offset - systemTimezone
      return dayjs().add(timezoneOffset, 'hours')
    },
    isPartyDate() {
      const now = this.getCurAmsterdamTime()
      return now.year() === partyYear && (now.month() + 1) === partyMonth && now.date() >= partyStartDate && now.date() <= partyEndDate
    },
    isPartyPlaying() {
      const now = this.getCurAmsterdamTime()
      return now.year() === partyYear && (((now.month() + 1) === partyMonth && now.date() >= partyStartDate) || (now.month() + 1) > partyMonth)
    },
    getCurTimeRangeList(targetTimezone, curTimezone, period) {
      const startDateTime = dayjs(this.selectDate).add(9, 'hours')
      const endDateTime = dayjs(this.selectDate).add(18, 'hours')
      const timezoneOffset = curTimezone - targetTimezone.offset
      console.log('timezoneOffset', timezoneOffset)
      const date = dayjs(startDateTime).date()
      const startTime = this.timeAdd(dayjs(startDateTime).valueOf(), timezoneOffset * 60)
      const endTime = this.timeAdd(dayjs(endDateTime).valueOf(), timezoneOffset * 60)
      let p = startTime
      console.log('startTime', dayjs(startDateTime).format('YYYY-MM-DD HH:mm'))
      const res = []
      while (p < endTime) {
        if (dayjs(p).date() === date) {
          const amsterdamTime = this.getCurAmsterdamTime()
          const listItemTime = dayjs(p)
          const disabled = this.isPartyDate() && (amsterdamTime.hour() > listItemTime.hour() || (amsterdamTime.hour() === listItemTime.hour() && amsterdamTime.minute() > listItemTime.minute()))
          res.push({
            text: dayjs(p).format('HH:mm'),
            value: p,
            startTime: p,
            endTime: this.timeAdd(p, 30),
            disabled
          })
        }
        p = this.timeAdd(p, 30)
      }
      return res
    }
  }
}
</script>
<style>
.v-application {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Microsoft YaHei, Tahoma, Arial;
}
</style>
<style scoped lang="scss">
@import "~@/styles/func.scss";

.divider {
  border-top: 1px solid #e7e7e7;
}

.banner-pic {
  width: 100%;
  background-color: #f5f5f5;
}

.success-container {
  padding-top: 150px;
}

.top-title {
  font-size: getRem(30);
  white-space: nowrap;
  color: #dd0011;
}

.error-box {
  margin-top: 200px;
}

.top-error-msg {
  font-size: 20px;
  white-space: nowrap;
  color: rgba(51, 51, 51, 0.7);
}

.bottom-info {
  opacity: 1;
  color: rgba(51, 51, 51, .7);
  text-align: left;
  font-weight: 400;
}

.red_color {
  color: #dd0011;
}

.product-container {
  //max-width: 130px;
  margin: 5px;
  text-align: center;
  border: 2px solid transparent !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 4px !important;
}

.product-container .product-img img {
  display: block;
  margin: auto;
  width: 100%;
}

.product-container .product-text {
  height: getRem(40);
}

.product-container.active {
  border: 2px solid #dd0011 !important;
  background-color: #f9f9f9;
}

.appointment-btn {
  margin: 5px;
}

.appointment-btn.active {
  background-color: #dd0011 !important;
  color: white;
}

.social-button {
  background-position: 25px 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  border-radius: 3px;
  margin: 10px auto;
  outline: rgb(255, 255, 255) none 0px;
  padding-left: 20%;
  transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#google-connect {
  background: rgb(255, 255, 255) url('~@/assets/google.png') no-repeat scroll 5px 8px / 35px 35px padding-box border-box;
  border: 1px solid rgb(220, 74, 61);
}

#google-connect:hover {
  border-color: rgb(220, 74, 61);
  background: rgb(220, 74, 61) url('~@/assets/google-white.png') no-repeat scroll 5px 8px / 35px 35px padding-box border-box;
  -webkit-transition: all .8s ease-out;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease-out;
}

#google-connect span {
  box-sizing: border-box;
  color: rgb(220, 74, 61);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: 0px none rgb(220, 74, 61);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#google-connect:hover span {
  color: #FFF;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#apple-connect {
  background: rgb(255, 255, 255) url('~@/assets/apple.png') no-repeat scroll 5px 5px / 35px 35px padding-box border-box;
  border: 1px solid #6f6f6f;
}

#apple-connect:hover {
  border-color: #6f6f6f;
  background: #6f6f6f url('~@/assets/apple-white.png') no-repeat scroll 5px 5px / 35px 35px padding-box border-box;
  -webkit-transition: all .8s ease-out;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease-out;
}

#apple-connect span {
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: 0px none rgb(220, 74, 61);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#apple-connect:hover span {
  color: #FFF;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#linkedin-connect {
  background: rgb(255, 255, 255) url('~@/assets/linkedin.svg') no-repeat scroll 13px 0px / 28px 45px padding-box border-box;
  border: 1px solid rgb(0, 119, 181);
}

#linkedin-connect:hover {
  border-color: rgb(0, 119, 181);
  background: rgb(0, 119, 181) url('~@/assets/linkedin-white.svg') no-repeat scroll 13px 0px / 28px 45px padding-box border-box;
  -webkit-transition: all .8s ease-out;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease-out;
}

#linkedin-connect span {
  box-sizing: border-box;
  color: rgb(0, 119, 181);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: 0px none rgb(0, 119, 181);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#linkedin-connect:hover span {
  color: #FFF;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.step-box {
  width: 100%;
}

.top-back {
  color: grey
}

</style>
